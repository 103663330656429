import { gql } from "@apollo/client";

export const editRPMutation = gql`
  mutation ($id: ID, $retailprice: Int) {
    rpEdit(id: $id, retailprice: $retailprice) {
      id
      name
      size
      currency
      thumbnail
      category
      gender
      state
      retailprice
      ishot
      isauthenticated
      price
      description
      itemstate
      brand
      shippingprice
    }
  }
`;

export const editGenderMutation = gql`
  mutation ($id: ID, $gender: String) {
    genderEdit(id: $id, gender: $gender) {
      id
      name
      size
      currency
      thumbnail
      category
      gender
      state
      ishot
      isauthenticated
      price
      description
      itemstate
      brand
      shippingprice
    }
  }
`;

export const editCategoryMutation = gql`
  mutation ($id: ID, $category: String) {
    categoryEdit(id: $id, category: $category) {
      id
      name
      size
      currency
      thumbnail
      category
      gender
      state
      ishot
      isauthenticated
      price
      description
      itemstate
      brand
      shippingprice
    }
  }
`;

export const editHotMutation = gql`
  mutation ($id: ID, $ishot: Int) {
    hotEdit(id: $id, ishot: $ishot) {
      id
      name
      size
      currency
      thumbnail
      category
      gender
      state
      ishot
      isauthenticated
      price
      description
      itemstate
      brand
      shippingprice
    }
  }
`;

export const editAuthenticityMutation = gql`
  mutation ($id: ID, $isauthenticated: Int) {
    authenticateEdit(id: $id, isauthenticated: $isauthenticated) {
      id
      name
      size
      currency
      thumbnail
      category
      gender
      state
      ishot
      isauthenticated
      price
      description
      itemstate
      brand
      shippingprice
    }
  }
`;
