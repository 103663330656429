import { useMutation, gql } from "@apollo/client";
import { Button, Input } from "antd";
import React, { useRef, useState } from "react";
import Sidebar from "../Sidebar";
import Header from "../../partials/Header";

export const sendNotifications = gql`
  mutation ($userType: Int, $title: String, $content: String) {
    sendMassNotification(
      userType: $userType
      title: $title
      content: $content
    ) {
      id
    }
  }
`;

function Notifications() {
  const contentArea = useRef(null);
  const [sendNoti] = useMutation(sendNotifications);
  const [title, setTitle] = useState("");
  const [sent, setSent] = useState(false);
  const [body, setBody] = useState("");

  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div
        className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
        ref={contentArea}
      >
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="relative bg-white">
            <div className="max-w-screen-xxl  mx-auto p-8  py-2 lg:py-8">
              <div className="grid grid-cols-4 gap-4">
                <Input
                  placeholder="Title"
                  onChange={(e) => setTitle(e.currentTarget.value)}
                  value={title}
                />
                <Input
                  placeholder="Content"
                  onChange={(e) => setBody(e.currentTarget.value)}
                  value={body}
                />

                <Button
                  color="green"
                  style={{ width: "45%" }}
                  disabled={sent}
                  loading={sent}
                  type="primary"
                  onClick={() => {
                    sendNoti({
                      variables: { content: body, title, userType: 1 },
                    });
                    setSent(true);
                  }}
                >
                  Send Notification
                </Button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Notifications;
