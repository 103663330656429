import React from "react";
import * as firebase from "firebase";
import { useAuthState } from "react-firebase-hooks/auth";

export function logout() {
  firebase.auth().signOut();
}

export function useFirebaseAuth() {
  const [user, loading, error] = useAuthState(firebase.auth());

  if (loading) {
    console.log(`init ${loading}`);
  }
  if (error) {
    console.log(error);
  }

  // if (localStorage.getItem("tokenString")) {
  //   const user = firebase
  //     .auth()
  //     //@ts-ignore
  //     .signInWithCustomToken(localStorage.getItem("tokenString"))
  //     .then((user) => user);
  //   console.log(user);
  // }

  const isAuthed = user !== null;
  console.log(user);
  return { loading, isAuthed, user };
}

export const withCurrentUser = (Component) => (props) => {
  const { user, isAuthed, loading } = useFirebaseAuth();

  return (
    <Component
      {...props}
      authUser={user}
      loading={loading}
      isAuthed={isAuthed}
    />
  );
};
