import { gql } from "@apollo/client";

export const getItems = gql`
  query (
    $query: String
    $size: String
    $brand: String
    $category: String
    $minprice: String
    $maxprice: String
    $country: String
    $limit: Int
    $offset: Int
  ) {
    Items(
      query: $query
      size: $size
      brand: $brand
      category: $category
      minprice: $minprice
      maxprice: $maxprice
      country: $country
      limit: $limit
      offset: $offset
    ) {
      id
      price
      name
      state
      currency
      gender
      retailprice
      category
      ishot
      isauthenticated
      brand
      thumbnail
      size
    }
  }
`;

export const getItem = gql`
  query ($itemid: String) {
    Item(itemid: $itemid) {
      id
      name
      images {
        id
        photoindex
        url
      }
      size
      thumbnail
      currency
      category
      state
      price
      ishot
      retailprice
      isauthenticated
      description
      itemstate
      brand
      shippingprice
      seller {
        id
        profilepic
        name
        countrycode

        regdate
        seller {
          id
          isverified
          displayname
          phonenumber
          instausername
          sellscount
        }
      }
    }
  }
`;
export const getPaginatedItems = gql`
  query (
    $query: String
    $size: String
    $brand: String
    $category: String
    $minprice: String
    $maxprice: String
    $gender: String
    $country: String
    $state: Int
    $ishot: Int
    $limit: Int
    $offset: Int
  ) {
    PaginatedItems(
      query: $query
      size: $size
      brand: $brand
      category: $category
      minprice: $minprice
      gender: $gender
      maxprice: $maxprice
      ishot: $ishot
      country: $country
      state: $state
      limit: $limit
      offset: $offset
    ) {
      count
      hasMore
      items {
        id
        name
        size
        thumbnail
        currency
        category
        state
        price
        ishot
        retailprice
        isauthenticated
        description
        itemstate
        brand
        shippingprice
      }
    }
  }
`;
