import {
  formatDistanceStrict,
  parseISO,
  parse,
  formatDistance,
  fromUnixTime,
  differenceInWeeks,
} from "date-fns";

export function dateToTime(date: number) {
  try {
    return formatDistanceStrict(fromUnixTime(date / 1000), new Date());
  } catch (error) {
    console.log(error);
  }
  // return "Test";
}

export function dateToTimeDistance(date: string) {
  try {
    return formatDistance(parseISO(date), new Date());
  } catch (error) {
    console.log(error);
  }
}

export function getWeeksDiff(date: number) {
  try {
    return differenceInWeeks(new Date(), fromUnixTime(date / 1000));
  } catch (error) {
    console.log(error);
  }
  // return "Test";
}
