import { useMutation } from "@apollo/client";
import React, { useState } from "react";

export default function ChatBottom({ conversation }: any) {
  const [input, setInput] = useState("");
  return (
    <div className="flex flex-row  items-center  bottom-0 my-2 w-full">
      <div className="ml-2 flex flex-row border-gray items-center w-full border rounded-3xl h-12 px-2">
        <button
          id="capture"
          className="focus:outline-none flex items-center justify-center h-10 w-8 hover:text-green-600 text-green-400 ml-1 mr-2"
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            ></path>
          </svg>
        </button>
        <div className="w-full">
          <input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            type="text"
            id="message"
            className="border rounded-2xl border-transparent w-full focus:outline-none text-sm h-10 flex items-center"
            placeholder="Type your message...."
          />
        </div>
      </div>
      <div className="ml-3 mr-2">
        <button
          // onClick={sendMessageMut}
          id="self"
          className="flex items-center justify-center h-10 w-10 mr-2 rounded-full bg-gray-200 hover:bg-gray-300 text-indigo-800 text-white focus:outline-none"
        >
          <svg
            className="w-5 h-5 transform rotate-90 -mr-px"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
}
