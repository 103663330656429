import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import SearchForm from "../partials/actions/SearchForm";
import SellerCard from "../partials/team/SellerCard";

import { useQuery } from "@apollo/client";
import { getSellers } from "../graphql/queries/Sellers";

function ApprovedSellers() {
  const { data, loading, fetchMore } = useQuery(getSellers, {
    variables: { status: 1, limit: 30, offset: 0 },
  });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  if (loading) return null;
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Approved Sellers
                </h1>
              </div>
            </div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {data.Sellers.map((item) => {
                return <SellerCard key={item.id} item={item} />;
              })}
            </div>

            {/* Pagination */}
            <div className="mt-8">{/* <PaginationNumeric /> */}</div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ApprovedSellers;
