import { useMutation, gql } from "@apollo/client";
import { Button } from "antd";
import React, { useState } from "react";
import withConversation from "../../../HOC/withConversation";
import { portNumber, serverURL } from "../../../utils/constant";
import ItemUnit from "../../campaigns/ItemUnit";
import axios from "axios";
import ChatBottom from "./ChatBottom";
import ChatHeader from "./ChatHeader";

import Message from "./Message";

export const updateBan = gql`
  mutation ($ID: String, $status: Float) {
    UpdateUserBan(ID: $ID, status: $status) {
      id
    }
  }
`;

function Conversation({ conversation }) {
  const [sending, setSending] = useState(false);
  const [updateUserBan] = useMutation(updateBan);
  if (!conversation) return null;

  return (
    <div className="flex flex-col bg-white ">
      <ChatHeader item={conversation.item} />
      <div className="grid lg:grid-cols-3 grid-rows-2  lg:gap-4">
        <div className="col-span-2">
          <div
            id="chat"
            className="flex flex-col mt-2 flex-col-reverse overflow-y-scroll space-y-3 pb-3"
          >
            {conversation.messages.map((msg, index) => {
              return <Message msg={msg} key={index} item={conversation.item} />;
            })}
          </div>
          <ChatBottom conversation={conversation} />
        </div>

        <div className="col-span-1 p-8">
          <ItemUnit item={conversation.item} />
          {/* <ConversationItem item={conversation.item} /> */}
          {/* <SellerUnit user={conversation.item.seller} fromConvo={true} /> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              color="green"
              style={{ width: "45%" }}
              disabled={sending}
              loading={sending}
              type="primary"
              onClick={() => {
                setSending(true);
                axios
                  .post(`${serverURL}:${portNumber}/sendEmail`, {
                    email: conversation.receiver.email,
                    itemName: conversation.item.name,
                    name: conversation.receiver.name,
                  })
                  .then(() => setSending(false))
                  .catch((e) => console.log(e));
              }}
            >
              Send Reminder
            </Button>
            <Button
              color="green"
              style={{ width: "45%" }}
              disabled={sending}
              loading={sending}
              type="primary"
              onClick={() => {
                setSending(true);
                axios
                  .post(`${serverURL}:${portNumber}/sendEmail`, {
                    email: conversation.receiver.paypaldata.paypalemail,
                    itemName: conversation.item.name,
                    name: conversation.receiver.name,
                  })
                  .then(() => setSending(false))
                  .catch((e) => console.log(e));
              }}
            >
              Send Reminder to paypal email
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 12,
            }}
          >
            <Button
              color="green"
              style={{ width: "45%" }}
              disabled={sending}
              loading={sending}
              type="primary"
              onClick={() => {
                setSending(true);
                axios
                  .post(`${serverURL}:${portNumber}/sendEmail`, {
                    email: conversation.sender.email,
                    itemName: conversation.item.name,
                    name: conversation.sender.name,
                  })
                  .then(() => setSending(false))
                  .catch((e) => console.log(e));
              }}
            >
              Send Reminder to buyer
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 12,
            }}
          >
            {conversation.sender?.status === 3 ? (
              <Button
                color="green"
                style={{ width: "45%" }}
                disabled={sending}
                loading={sending}
                type="primary"
                onClick={() => {
                  setSending(true);
                  updateUserBan({
                    variables: { ID: conversation.sender.id, status: 0 },
                  }).then(() => setSending(false));
                  //   axios
                  //     .post(`${serverURL}:${portNumber}/sendEmail`, {
                  //       email: conversation.item.seller.email,
                  //       itemName: conversation.item.name,
                  //       name: conversation.item.seller.name,
                  //     })
                  //     .then(() => setSending(false))
                  //     .catch((e) => console.log(e));
                  // }}
                }}
              >
                Unban buyer
              </Button>
            ) : (
              <Button
                color="red"
                style={{ width: "45%" }}
                disabled={sending}
                loading={sending}
                type="primary"
                onClick={() => {
                  setSending(true);
                  updateUserBan({
                    variables: { ID: conversation.sender.id, status: 3 },
                  }).then(() => setSending(false));
                  //   axios
                  //     .post(`${serverURL}:${portNumber}/sendEmail`, {
                  //       email: conversation.item.seller.email,
                  //       itemName: conversation.item.name,
                  //       name: conversation.item.seller.name,
                  //     })
                  //     .then(() => setSending(false))
                  //     .catch((e) => console.log(e));
                  // }}
                }}
              >
                Ban buyer
              </Button>
            )}
            {conversation.item.seller?.status === 3 ? (
              <Button
                color="red"
                style={{ width: "45%" }}
                disabled={sending}
                loading={sending}
                type="primary"
                onClick={() => {
                  setSending(true);
                  updateUserBan({
                    variables: { ID: conversation.receiver.id, status: 0 },
                  }).then(() => setSending(false));
                  //   axios
                  //     .post(`${serverURL}:${portNumber}/sendEmail`, {
                  //       email: conversation.item.seller.email,
                  //       itemName: conversation.item.name,
                  //       name: conversation.item.seller.name,
                  //     })
                  //     .then(() => setSending(false))
                  //     .catch((e) => console.log(e));
                  // }}
                }}
              >
                UnBan seller
              </Button>
            ) : (
              <Button
                color="red"
                style={{ width: "45%" }}
                disabled={sending}
                loading={sending}
                type="primary"
                onClick={() => {
                  setSending(true);
                  updateUserBan({
                    variables: { ID: conversation.sender.id, status: 3 },
                  }).then(() => setSending(false));
                  //   axios
                  //     .post(`${serverURL}:${portNumber}/sendEmail`, {
                  //       email: conversation.item.seller.email,
                  //       itemName: conversation.item.name,
                  //       name: conversation.item.seller.name,
                  //     })
                  //     .then(() => setSending(false))
                  //     .catch((e) => console.log(e));
                  // }}
                }}
              >
                Ban seller
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withConversation(Conversation);
