import React, { useState, useEffect, useRef } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import ChatsList from "../partials/Chat/ChatsList";
import Conversation from "../partials/Chat/Conversation";
import { getConversations } from "../graphql/queries/Messaging";
import { useQuery } from "@apollo/client";

function Messages() {
  const contentArea = useRef(null);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [msgSidebarOpen, setMsgSidebarOpen] = useState(true);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const { loading, data, error, fetchMore } = useQuery(getConversations, {
    variables: { limit: 60, offset: 0 },
  });

  if (loading) return null;
  if (error) return <span>{error}</span>;

  // const sortedConvos: any = data.Conversations.sort((a: any, b: any) =>
  //   a.lastmessage?.messagedate < b.lastmessage?.messagedate ? 1 : -1
  // );

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div
        className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
        ref={contentArea}
      >
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="relative bg-white">
            <div className="max-w-screen-xxl  mx-auto p-8  py-2 lg:py-8">
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-1">
                  <ChatsList
                    conversations={data.Conversations}
                    setConversation={setSelectedConversation}
                  />
                </div>
                <div className="col-span-3 h-full">
                  <Conversation conversation={selectedConversation} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Messages;
