import React from "react";

import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

interface PaginationInterface {
  pageInfo: { hasNextPage: boolean };
  children: React.ReactChildren | React.ReactChild;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  totalPages: number;
}

const PaginationComponent: React.FC<PaginationInterface> = ({
  pageInfo,
  children,
  setCurrentPage,
  currentPage,
  totalPages,
}) => {
  const { hasNextPage } = pageInfo;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const renderControls = () => (
    <div
      style={{
        paddingTop: 32,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <button
        style={{
          background: "#f2f3f7",
          borderRadius: 32,
          color: "white",
          cursor: "pointer",
          fill: "white",
          height: "3rem",
          margin: "0 3rem",
          padding: "0.5rem",
          width: "3rem",
        }}
        disabled={currentPage === 1}
        onClick={handlePrevClick}
      >
        <MdKeyboardArrowLeft color="#8e8f94" size={32} />
      </button>

      {totalPages && totalPages > 0 ? (
        <span style={{ alignItems: "center", display: "flex" }}>
          <span style={{ color: "#4e9fea", fontSize: 24 }}>{currentPage}</span>{" "}
          <span
            style={{
              color: "#a5a6aa",
              fontSize: 24,
              fontWeight: "lighter",
              marginLeft: 12,
              marginRight: 12,
            }}
          >
            |
          </span>
          <span style={{ color: "#a5a6aa", fontSize: 16 }}>{totalPages}</span>
        </span>
      ) : (
        <span>No results</span>
      )}
      <button
        style={{
          background: "#f2f3f7",
          borderRadius: 32,
          color: !hasNextPage ? "gray" : "white",
          cursor: !hasNextPage ? "not-allowed" : "pointer",
          fill: "white",
          height: "3rem",
          margin: "0 3rem",
          padding: "0.5rem",
          width: "3rem",
        }}
        disabled={!hasNextPage}
        onClick={handleNextClick}
      >
        <MdKeyboardArrowRight color="#8e8f94" size={32} />
      </button>
    </div>
  );

  return (
    <div style={{ fontSize: "var(--font-size-xlg)", width: "100%" }}>
      {children}
      {renderControls()}
    </div>
  );
};
// &:disabled {
//   color: gray;
//   cursor: not-allowed;

export default PaginationComponent;
