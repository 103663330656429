export const PrimaryButton = ({ text, onClick, className }: any) => {
  return (
    <button
      onClick={onClick}
      className={`inline-flex items-center justify-center px-4 py-2 lg:text-base text-xs font-medium text-white bg-black border border-transparent rounded-md shadow-sm cursor-pointer whitespace-nowrap hover:bg-black-700 ${className}`}
    >
      <span>{text}</span>
      {/* <Text
        type="small"
        text={text}
        color="white"
        className="tracking-widest"
      /> */}
    </button>
  );
};

export const SecondaryButton = ({ text, onClick, className }: any) => {
  return (
    <button
      onClick={onClick}
      className={`inline-flex items-center justify-center px-4 py-2 lg:text-base text-xs font-medium text-black border border-transparent rounded-md shadow-md cursor-pointer whitespace-nowrap ${className}`}
    >
      <span>{text}</span>

      {/* <Text
        type="small"
        text={text}
        color="black"
        className="tracking-widest"
      /> */}
    </button>
  );
};
