import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import AuthImage from "../images/auth-image.jpg";
import AuthDecoration from "../images/auth-decoration.png";
import { withCurrentUser } from "../HOC/user-hooks";
import firebase from "firebase";

function Signin({ authUser }) {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  if (authUser && authUser.email === "admin@sellingcommunity.com") {
    history.push("/");
  }

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                Hi Frend
              </h1>
              {/* Form */}
              <form>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                      id="email"
                      className="form-input w-full"
                      type="email"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      onChange={(e) => setPassword(e.currentTarget.value)}
                      value={password}
                      id="password"
                      className="form-input w-full"
                      type="password"
                      autoComplete="on"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <Link
                      className="text-sm underline hover:no-underline"
                      onClick={() => alert("u tripping?")}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <div
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 cursor-pointer"
                    onClick={async () => {
                      await firebase
                        .auth()
                        .signInWithEmailAndPassword(email, password)
                        .then(async (authUser) => {
                          if (
                            authUser?.user?.email ===
                            "admin@sellingcommunity.com"
                          ) {
                            localStorage.setItem(
                              "tokenString",
                              await authUser.user?.getIdToken()
                            );
                          }
                        })
                        .catch((error) => console.log(error));
                    }}
                  >
                    Sign In
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2 bg-black"
          aria-hidden="true"
        >
          <h2>SELLING COMMUNITY</h2>
        </div>
      </div>
    </main>
  );
}

export default withCurrentUser(Signin);
