/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useQuery } from "@apollo/client";
import { getConversation } from "../graphql/queries/Messaging";

const withConversation = (Component) => (props) => {
  if (!props.conversation) return null;
  const { loading, error, data, fetchMore, subscribeToMore, refetch } =
    useQuery(getConversation, {
      variables: {
        conversationid: props.conversation,
        limit: 30,
        offset: 0,
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      pollInterval: 180000,
    });
  if (loading) {
    return <span>Loading</span>;
  }
  if (error) {
    return (
      <span>
        Error!
        {error.message}
      </span>
    );
  }

  return <Component {...props} conversation={data.Conversation} />;
};

export default withConversation;
