import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Gender from "./Gender";
import { FaSpinner, FaFireAlt, FaFireExtinguisher } from "react-icons/fa";
import { MdVerifiedUser } from "react-icons/md";

import {
  editAuthenticityMutation,
  editCategoryMutation,
  editGenderMutation,
  editHotMutation,
  editRPMutation,
} from "../../graphql/mutations/item";
import { useMutation } from "@apollo/client";
import { PrimaryButton, SecondaryButton } from "../../UI/Button";

import { categories } from "../../utils/categories";
import { TreeSelect } from "antd";

export default function ItemUnit({ item, gender }: any) {
  const history = useHistory();
  const [RP, setRP] = useState(item.retailprice || "");
  const [editGender, { loading, error }] = useMutation(editGenderMutation, {});
  const [editRP, { loading: loadingRP, error: errorRP }] = useMutation(
    editRPMutation,
    {}
  );

  const [editCategory, { loading: loadingCategory }] = useMutation(
    editCategoryMutation,
    {}
  );
  const [editHotness, { loading: loadingHotness }] = useMutation(
    editHotMutation,
    {}
  );
  const [editAuthenticity, { loading: loadingAuthenticity }] = useMutation(
    editAuthenticityMutation,
    {}
  );
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        height: "auto",
        width: 300,
        padding: 16,
        backgroundColor: "white",
        borderRadius: 16,

        margin: 12,
      }}
    >
      <img src={item.thumbnail} style={{ width: "100%", height: "auto" }} />
      <div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span style={{ fontSize: 15, fontWeight: "bold" }}>{item.name}</span>
        <span>{item.price}</span>
      </div>
      <span>{item.size}</span>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          marginTop: 8,
          marginBottom: 8,
          justifyContent: "space-around",
        }}
      >
        {loadingHotness ? (
          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <FaSpinner />
          </div>
        ) : (
          <>
            <div
              onClick={() =>
                editHotness({
                  variables: { id: item.id, ishot: item.ishot ? 0 : 1 },
                })
              }
              style={{
                textAlign: "center",
                borderWidth: 1,
                borderColor: item.ishot ? "red" : "black",
                borderRadius: 12,
                borderStyle: "solid",
                cursor: "pointer",
                padding: 16,
              }}
            >
              <FaFireAlt size={36} color={item.ishot ? "red" : "black"} />
            </div>
          </>
        )}
        {loadingAuthenticity ? (
          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <FaSpinner />
          </div>
        ) : (
          <>
            <div
              onClick={() =>
                editAuthenticity({
                  variables: {
                    id: item.id,
                    isauthenticated: item.isauthenticated ? 0 : 1,
                  },
                })
              }
              style={{
                textAlign: "center",
                borderWidth: 1,
                borderColor: item.isauthenticated ? "green" : "black",
                borderRadius: 12,
                borderStyle: "solid",
                cursor: "pointer",
                padding: 16,
              }}
            >
              <MdVerifiedUser
                size={36}
                color={item.isauthenticated ? "green" : "black"}
              />
            </div>
          </>
        )}
      </div>
      {false && (
        <div style={{ flexDirection: "row", display: "flex" }}>
          {loading ? (
            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
              <FaSpinner />
            </div>
          ) : (
            <>
              <Gender
                value={0}
                selected={item.gender === "0"}
                text="UniSex"
                onClick={() =>
                  editGender({ variables: { id: item.id, gender: "0" } })
                }
              />
              <Gender
                value={1}
                selected={item.gender === "1"}
                text="Male"
                onClick={() =>
                  editGender({ variables: { id: item.id, gender: "1" } })
                }
              />
              <Gender
                value={2}
                selected={item.gender === "2"}
                text="Female"
                onClick={() =>
                  editGender({ variables: { id: item.id, gender: "2" } })
                }
              />
            </>
          )}
        </div>
      )}

      {loadingCategory ? (
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <FaSpinner />
        </div>
      ) : (
        // <SelectPicker
        //   data={categories}
        //   groupBy="parent"
        //   onChange={(e) =>
        //     editCategory({ variables: { id: item.id, category: e } })
        //   }
        //   style={{ width: "100%" }}
        //   defaultValue={item.category}
        //   labelKey="name"
        //   valueKey="name"
        //   cleanable={false}
        // />
        <TreeSelect
          showSearch
          style={{ width: "100%" }}
          defaultValue={item.category}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          placeholder="Please select"
          allowClear
          treeDefaultExpandAll
          treeData={categories}
          onChange={(e) =>
            editCategory({ variables: { id: item.id, category: e } })
          }
        />
        // <DropdownTreeSelect
        //   keepTreeOnSearch
        //   mode="radioSelect"
        //   showPartiallySelected
        //   data={categories}
        //   onChange={(e) => alert(e.value)}
        // />
      )}
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          padding: 8,
          marginTop: 12,
        }}
      >
        <input
          value={RP}
          type="number"
          placeholder="Retail price"
          onChange={(e) => setRP(e.currentTarget.value)}
          style={{ width: "80%", marginRight: 12 }}
        />
        <PrimaryButton
          text="Set RP"
          color="blue"
          onClick={() =>
            editRP({ variables: { id: item.id, retailprice: Number(RP) } })
          }
        />
      </div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          padding: 16,
          marginTop: 12,
        }}
      >
        <PrimaryButton
          color="blue"
          onClick={() => history.push(`/item/${item.id}`)}
          text="Check"
        />
        <SecondaryButton text="Remove" />
      </div>
    </div>
  );
}
