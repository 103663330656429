import React from "react";
import { dateToTime } from "../../utils/dateUtil";

export default function ChatsList({ conversations, setConversation }) {
  //   <div
  //   className="grid grid-cols-4 gap-4"
  //   onClick={() => setConversation(convo.id)}
  // >
  //   <div className="...">

  //   </div>
  //   <div className="col-span-2 flex flex-col">
  //     <span></span>
  //     <span></span>
  //   </div>
  //   <div className="">

  //   </div>

  return (
    <div className="bg-white">
      <span className="text-xl">Chats</span>
      <div>
        {conversations.map((convo) => (
          <div
            className="flex flex-col -mx-4 cursor-pointer"
            onClick={() => setConversation(convo.id)}
          >
            <div className="relative flex flex-row items-center p-4 ">
              <div className="absolute text-xs text-gray-500 right-0 top-0 mr-4 mt-3">
                {dateToTime(convo?.lastmessage?.messagedate)}
              </div>
              <div className="flex items-center justify-center h-10 w-10 rounded-full bg-pink-500 text-pink-300 font-bold flex-shrink-0">
                <img src={convo.item.thumbnail} className="rounded-xl" />
              </div>
              <div className="flex flex-col flex-grow ml-3">
                <div className="text-sm font-medium">{convo.item.name}</div>
                <div className="text-xs truncate w-40">
                  {convo.lastmessage?.messagetext}
                </div>
              </div>
              {/* <div className="flex-shrink-0 ml-2 self-end mb-1">
                <span className="flex items-center justify-center h-5 w-5 bg-red-500 text-white text-xs rounded-full">
                  5
                </span>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

{
  /* <div class="flex flex-row items-center p-4 bg-gradient-to-r from-red-100 to-transparent border-l-2 border-red-500"> */
}
