import React, { useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { GiRunningShoe } from "react-icons/gi";
import { AiOutlineMessage } from "react-icons/ai";
import { RiDashboardLine } from "react-icons/ri";
import { FcMoneyTransfer } from "react-icons/fc";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;
  const page = pathname.split("/")[1];

  const trigger = useRef(null);
  const sidebar = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="lg:w-64">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 flex-shrink-0 bg-gray-800 p-4 transition-transform duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-gray-500 hover:text-gray-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink exact to="/" className="block">
            SELLING COMMUNITY
          </NavLink>
        </div>

        {/* Links */}
        <div>
          <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">
            Pages
          </h3>
          <ul className="mt-3">
            {/* Dashboard */}
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "" && "hover:text-gray-200"
                }`}
              >
                <div className="flex flex-grow">
                  <RiDashboardLine style={{ marginRight: 8 }} size={24} />
                  <span className="text-sm font-medium">Dashboard</span>
                </div>
              </NavLink>
            </li>
            {/* Customers */}
            {/* <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "customers" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/customers"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "customers" && "hover:text-gray-200"
                }`}
              >
                <div className="flex flex-grow">
                  <svg
                    className="flex-shrink-0 h-6 w-6 mr-3"
                    viewBox="0 0 24 24"
                  >
                    <path
                      className={`fill-current text-gray-400 ${
                        page === "customers" && "text-indigo-300"
                      }`}
                      d="M7 0l6 7H8v10H6V7H1z"
                    />
                    <path
                      className={`fill-current text-gray-600 ${
                        page === "customers" && "text-indigo-500"
                      }`}
                      d="M18 7v10h5l-6 7-6-7h5V7z"
                    />
                  </svg>
                  <span className="text-sm font-medium">Customers</span>
                </div>
              </NavLink>
            </li> */}
            {/* Orders */}
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "orders" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/orders"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "orders" && "hover:text-gray-200"
                }`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex flex-grow">
                    <FcMoneyTransfer style={{ marginRight: 8 }} size={24} />
                    <span className="text-sm font-medium">Orders</span>
                  </div>
                  {/* Badge */}
                  {/* <div className="flex flex-shrink-0 ml-2">
                    <span className="inline-flex items-center justify-center h-5 text-xs font-medium text-white bg-indigo-500 px-2 rounded-sm">
                      4
                    </span>
                  </div> */}
                </div>
              </NavLink>
            </li>
            {/* Campaigns */}
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "items" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/items"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "items" && "hover:text-gray-200"
                }`}
              >
                <div className="flex flex-grow">
                  <GiRunningShoe style={{ marginRight: 8 }} size={24} />
                  <span className="text-sm font-medium">Items</span>
                </div>
              </NavLink>
            </li>
            {/* Team */}
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page.startsWith("team-") && "bg-gray-900"
              }`}
            >
              <div
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page.startsWith("sellers-") && "hover:text-gray-200"
                }`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex flex-grow">
                    <svg
                      className="flex-shrink-0 h-6 w-6 mr-3"
                      viewBox="0 0 24 24"
                    >
                      <path
                        className={`fill-current text-gray-600 ${
                          page.startsWith("sellers-") && "text-indigo-500"
                        }`}
                        d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
                      />
                      <path
                        className={`fill-current text-gray-400 ${
                          page.startsWith("sellers-") && "text-indigo-300"
                        }`}
                        d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
                      />
                    </svg>
                    <span className="text-sm font-medium">Sellers</span>
                  </div>
                  {/* Icon */}
                  <div className="flex flex-shrink-0 ml-2">
                    <svg
                      className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${
                        page.startsWith("sellers-") && "transform rotate-180"
                      }`}
                      viewBox="0 0 12 12"
                    >
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </div>
                </div>
              </div>
              <ul className={`pl-9 mt-1`}>
                <li className="mb-1 last:mb-0">
                  <NavLink
                    exact
                    to="/sellers-pending"
                    className={`block text-gray-200 hover:text-white transition duration-150 ${
                      page === "sellers-pending" &&
                      "text-indigo-400 hover:text-indigo-400"
                    }`}
                  >
                    <span className="text-sm font-medium">
                      Sellers - Pending
                    </span>
                  </NavLink>
                </li>
                <li className="mb-1 last:mb-0">
                  <NavLink
                    exact
                    to="/sellers-approved"
                    className={`block text-gray-200 hover:text-white transition duration-150" ${
                      page === "sellers-approved" &&
                      "text-indigo-400 hover:text-indigo-400"
                    }`}
                  >
                    <span className="text-sm font-medium">
                      Sellers - Approved
                    </span>
                  </NavLink>
                </li>
                <li className="mb-1 last:mb-0">
                  <NavLink
                    exact
                    to="/sellers-refused"
                    className={`block text-gray-200 hover:text-white transition duration-150" ${
                      page === "sellers-refused" &&
                      "text-indigo-400 hover:text-indigo-400"
                    }`}
                  >
                    <span className="text-sm font-medium">
                      Sellers - Refused
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* Messages */}
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "messages" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/messages"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "messages" && "hover:text-gray-200"
                }`}
              >
                <div className="flex flex-grow">
                  <AiOutlineMessage style={{ marginRight: 8 }} size={24} />
                  <span className="text-sm font-medium">Messages</span>
                </div>
              </NavLink>
            </li>
            <li
              className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                page === "notifications" && "bg-gray-900"
              }`}
            >
              <NavLink
                exact
                to="/notifications"
                className={`block text-gray-200 hover:text-white transition duration-150 ${
                  page === "notifications" && "hover:text-gray-200"
                }`}
              >
                <div className="flex flex-grow">
                  <AiOutlineMessage style={{ marginRight: 8 }} size={24} />
                  <span className="text-sm font-medium">Notifications</span>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
