import React, { useEffect } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";

import "./css/style.scss";

import { focusHandling } from "cruip-js-toolkit";
import "./charts/ChartjsConfig";

// Import pages
import Dashboard from "./pages/Dashboard";
import Customers from "./pages/Customers";
import Orders from "./pages/Orders";
import Campaigns from "./pages/Campaigns";
import TeamTabs from "./pages/TeamTabs";
import Messages from "./pages/Messages";
import Tasks from "./pages/Tasks";
import Applications from "./pages/Applications";
import Settings from "./pages/Settings";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import { portNumber, serverURL } from "./utils/constant";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { Observable } from "@apollo/client/utilities";
import RefusedSellers from "./pages/RefusedSellers";
import ApprovedSellers from "./pages/ApprovedSellers";
import PendingSellers from "./pages/PendingSellers";
import Item from "./pages/Item";
import firebase from "firebase";
import { withCurrentUser } from "./HOC/user-hooks";
import Notifications from "./partials/notifications";

const firebaseConfig = {
  apiKey: "AIzaSyCXQuEDPO_i61MIOUfh0TV6udzsh1uCbDY",
  authDomain: "sellingcommunity-b2488.firebaseapp.com",
  databaseURL: "https://sellingcommunity-b2488.firebaseio.com/",
  projectId: "sellingcommunity-b2488",
  storageBucket: "sellingcommunity-b2488.appspot.com",
  messagingSenderId: "555601736533",
};
if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser && authUser.email === "admin@sellingcommunity.com" ? (
        <>
          <Component {...props} />
        </>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

function App({ authUser, loading }) {
  const location = useLocation();
  const cache = new InMemoryCache();

  useEffect(() => {
    //@ts-ignore
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    //@ts-ignore
    document.querySelector("html").style.scrollBehavior = "";
    //@ts-ignore
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  const httpLink = createHttpLink({
    uri: `${serverURL}:${portNumber}/graphql`,
    // credentials: "include",
  });

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle: any;
        Promise.resolve(operation)
          .then(async (operation) => {
            const accessToken = await firebase
              .auth()
              ?.currentUser?.getIdToken();
            if (accessToken) {
              operation.setContext({
                headers: {
                  authorization: `bearer ${accessToken}`,
                },
              });
            }
          })
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

  const client = new ApolloClient({
    // resolvers,
    link: ApolloLink.from([requestLink, httpLink]),
    cache,
    connectToDevTools: true,
  });
  if (loading) return <div>Loading</div>;
  return (
    <ApolloProvider client={client}>
      <Switch>
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/"
          component={Dashboard}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/customers"
          component={Customers}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/orders"
          component={Orders}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/items"
          component={Campaigns}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/item/:itemid"
          component={Item}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/team-tabs"
          component={TeamTabs}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/sellers-refused"
          component={RefusedSellers}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/sellers-approved"
          component={ApprovedSellers}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/notifications"
          component={Notifications}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/sellers-pending"
          component={PendingSellers}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/messages"
          component={Messages}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/tasks"
          component={Tasks}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/applications"
          component={Applications}
        />
        <RestrictedRoute
          exact
          authUser={authUser}
          path="/settings"
          component={Settings}
        />
        <Route exact path="/login">
          <Signin />
        </Route>
        <Route exact path="/signup">
          <Signup />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>
      </Switch>
    </ApolloProvider>
  );
}

export default withCurrentUser(App);
