import { gql } from "@apollo/client";

export const getConversation = gql`
  query ($conversationid: String, $limit: Float, $offset: Float) {
    Conversation(
      conversationid: $conversationid
      limit: $limit
      offset: $offset
    ) {
      id
      createdate
      receiver {
        id
        name
        status
        email
        paypaldata {
          id
          paypalemail
          name
          address
          verified
        }
      }
      sender {
        id
        name
        email
        status
      }
      item {
        id
        name
        thumbnail
        price
        currency
        size
        state
        seller {
          id
        }
      }
      messages {
        id
        messagetext
        sender {
          id
        }
        read
        messagedate
      }
    }
  }
`;

export const getConversations = gql`
  query ($limit: Float, $offset: Float) {
    Conversations(limit: $limit, offset: $offset) {
      id
      lastmessage {
        id
        messagetext
        messagedate
      }
      createdate
      item {
        id
        name
        thumbnail
        price
        currency
        state
        seller {
          id
          name
          profilepic
          email
          countrycode
          regdate
          isseller
          paypaldata {
            id
            paypalemail
            name
            address
            verified
          }
          seller {
            id
            displayname
            sellscount
            isverified
            phonenumber
            instausername
            didsellingonco
            recousername
            recommended
            didsellingonco
          }
        }
      }
      receiver {
        id
        name
        email
        status
      }
      sender {
        id
        name
        email
        status
      }
    }
  }
`;
