import { gql } from "@apollo/client";

export const getSellers = gql`
  query ($status: Int, $limit: Float, $offset: Float) {
    Sellers(status: $status, limit: $limit, offset: $offset) {
      id
      name
      profilepic
      countrycode
      regdate
      isseller
      paypaldata {
        id
        verified
        name
        paypalemail
        address
      }
      seller {
        id
        displayname
        sellscount
        isverified
        phonenumber
        instausername
        didsellingonco
        recousername
        recommended
        didsellingonco
      }
    }
  }
`;

export const ChangeSellerStatus = gql`
  mutation ($userid: String, $status: Int) {
    ChangeSellerStatus(userid: $userid, status: $status) {
      id
      name
      isseller
    }
  }
`;

export const ChangeUserVerify = gql`
  mutation ($userid: String, $isverified: Boolean) {
    ChangeUserVerify(userid: $userid, isverified: $isverified) {
      id
      isverified
    }
  }
`;
