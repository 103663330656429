import React, { useState } from "react";
import { getItem } from "../graphql/queries/Items";
import { useRouteMatch, useHistory } from "react-router-dom";
// import LoadingScreen from "../components/LoadingScreen";
// import SellerUnit from "../components/SellerUnit";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { Carousel } from "antd";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";

export const removeItem = gql`
  mutation ($id: ID) {
    removeItem(id: $id) {
      id
    }
  }
`;

export default function Item() {
  const route = useRouteMatch();
  const [deleteItem] = useMutation(removeItem);
  const history = useHistory();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  //@ts-ignore
  const itemid = route.params.itemid;
  const { data, loading, error } = useQuery(getItem, { variables: { itemid } });
  if (loading)
    return <div style={{ width: "55%", background: "#f3f6fb" }}>loading</div>;
  if (error) return <span>{error}</span>;
  const item = data.Item;
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div
        className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
        style={{ background: "#f2f2f2" }}
      >
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="grid grid-rows-1 md:grid-cols-2 gap-6">
              <div className="col-span-1">
                <Carousel style={{ borderRadius: 36, width: "80%" }}>
                  {item.images.map((image, index) => {
                    return <img src={image.url} style={{ borderRadius: 36 }} />;
                  })}
                </Carousel>
              </div>
              <div
                className="col-span-1"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: 48,
                  marginLeft: 12,
                  background: "#f2f2f2",
                }}
              >
                <span style={{ fontSize: 24 }}>{item.name}</span>
                <span style={{ fontSize: 16, color: "gray" }}>
                  {item.brand}
                </span>
                <span style={{ fontSize: 16 }}>{item.size}</span>
                <span style={{ marginTop: 24, fontSize: 16 }}>
                  {item.price} {item.currency} + {item.shippingprice}{" "}
                  {item.currency} (Postage)
                </span>
                <span>Condition: {item.state === 0 ? "Used" : "New"}</span>
                Description:
                <span style={{ fontWeight: "bold" }}>{item.description}</span>
                <button
                  // disabled={isRefusing}
                  // loading={isRefusing}
                  style={{
                    width: "90%",
                    height: 60,
                    backgroundColor: "black",
                    color: "white",
                    marginTop: 48,
                    marginBottom: 48,
                  }}
                  onClick={() =>
                    deleteItem({ variables: { id: item.id } })
                      .then((e) => {
                        // history.goBack();
                      })
                      .catch((e) => alert("An error has happened"))
                  }

                  //   setIsRefusing(true);
                  //   updateSellerStatus({
                  //     variables: { userid: user.id, status: 3 },
                  //   }).then(() => setIsRefusing(false));
                  // }}
                >
                  Delete Item
                </button>
                {/* <SellerUnit user={item.seller} fromConvo={true} /> */}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
