import React from "react";
// import ListUnit from 'src/components/ListUnit'

export default function ChatHeader({ item }) {
  return (
    <div className="grid grid-cols-5 gap-4 p-4 flex items-center">
      <div className="col-span-3">{/* <ListUnit item={item} /> */}</div>

      <div className="col-span-2 flex justify-end">
        {/* <a className="inline-flex items-center justify-center px-4 py-2 ml-8 text-base font-medium text-black bg-white border border-transparent rounded-md shadow-sm cursor-pointer whitespace-nowrap hover:bg-black hover:text-white">
          Offer
        </a> */}
      </div>
    </div>
  );
}
