import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import SearchForm from "../partials/actions/SearchForm";
import FilterButton from "../partials/actions/FilterButton";
import CampaignsCard from "../partials/campaigns/CampaignsCard";
import { getItems, getPaginatedItems } from "../graphql/queries/Items";
import { useQuery } from "@apollo/client";
import ItemUnit from "../partials/campaigns/ItemUnit";
import PaginationComponent from "../partials/Pagination";

function Items() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [queryText, setQueryText] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  const [refreshing, setRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [query, setQuery] = useState("");
  const [filterPriceRangeMin, setFilterPriceRangeMin] = useState("");
  const [filterPriceRangeMax, setFilterPriceRangeMax] = useState("");
  const [sortModal, setSortModal] = useState(false);
  const [brandsModal, setBrandsModal] = useState(false);
  const [filterBrand, setFilterBrand] = useState("ANY");
  const [filterCategory, setFilterCategory] = useState("ANY");
  const [filterSize, setFilterSize] = useState("ANY");
  const [filterCountry, setFilterCountry] = useState("ANY");
  const { subscribeToMore, loading, error, data, fetchMore, refetch } =
    useQuery(getPaginatedItems, {
      variables: {
        query,
        brand: filterBrand,
        size: filterSize,
        country: filterCountry,
        category: filterCategory,
        minprice: filterPriceRangeMin,
        maxprice: filterPriceRangeMax,
        offset: currentPage * 20,
        limit: 20,
      },
      pollInterval: 600000,
    });
  if (loading) return <></>;
  if (error)
    return <span style={{ marginTop: 67 }}>Error! ${error.message}</span>;

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
                  Items
                </h1>
              </div>

              {/* Right: Actions */}
            </div>

            {/* Cards */}

            <PaginationComponent
              pageInfo={{ hasNextPage: true }}
              currentPage={currentPage}
              setCurrentPage={(e) => setCurrentPage(e)}
              totalPages={totalPages}
            >
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {data?.PaginatedItems?.items.map((item) => {
                  return <ItemUnit key={item.id} item={item} />;
                })}
              </div>
            </PaginationComponent>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Items;
