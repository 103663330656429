import React from "react";

export default function PaypalAddress({ address }: any) {
  if (!address) {
    return null;
  }
  return (
    <div>
      <span>
        {`${address.street_address} ,${address.locality}, ${address.region}, ${address.postal_code},${address.country}`}
      </span>
    </div>
  );
}
