import React from "react";

export default function Message({ msg, item }) {
  const sentMessage = (m) => {
    return (
      <div className="relative mr-3 text-sm bg-blue-500 py-2 px-4 shadow rounded-xl w-max ml-auto break-all mt-2 mb-1 p-2 rounded-br-none  rounded-2xl text-white text-left mr-5">
        {m.messagetext}
      </div>
    );
  };
  const receivedMessage = (m) => {
    return (
      <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl other break-all mt-2  ml-5 rounded-bl-none float-none  mr-auto rounded-2xl p-2">
        {m.messagetext}
      </div>
    );
  };

  const systemMessage = (m) => {
    return (
      <div className="other break-all mb-2 mt-2 mr-auto ml-auto rounded float-center bg-black text-white rounded-2xl p-2">
        {m.messagetext}
      </div>
    );
  };
  if (msg.messagetext.startsWith("https")) {
    return (
      <img
        src={msg.messagetext}
        style={{ width: 240, height: 240 }}
        className={`${
          msg.sender.id === item.seller.id
            ? "w-max ml-auto break-all mt-2 mb-1 p-2 rounded-br-none bg-blue-500 rounded-2xl text-white text-left mr-5"
            : "other break-all mt-2  ml-5 rounded-bl-none float-none bg-gray-300 mr-auto rounded-2xl p-2"
        } `}
      />
    );
  } else if (msg.sender.id == 0) {
    return systemMessage(msg);
  } else if (msg.sender.id === item.seller.id) {
    return sentMessage(msg);
  } else {
    return receivedMessage(msg);
  }
}
