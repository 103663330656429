export const categories = [
  {
    value: "Accessories",
    label: "Accessories",
    icon: "../assets/icons/watch.png",
    description: "",
    children: [
      { label: "Jewelry & Watches", value: "Jewelry & Watches" },
      { label: "Belts", value: "Belts" },
      { label: "Glasses", value: "Glasses" },
      { label: "Gloves & Scarves", value: "Gloves & Scarves" },
      { label: "Hats", value: "Hats" },
      { label: "Wallets & Cardholders", value: "Wallets & Cardholders" },
      { label: "Socks & Underwear", value: "Socks & Underwear" },
      { label: "Sunglasses", value: "Sunglasses" },
      { label: "Ties & Pocketsquares", value: "Ties & Pocketsquares" },
      {
        label: "Collectibles & Decorative Accessories",
        value: "Collectibles & Decorative Accessories",
      },
      { label: "Passport Holder", value: "Passport Holder" },
      { label: "Phone Cases", value: "Phone Cases" },
      { label: "Keyrings", value: "Keyrings" },
      { label: "Bracelets & Chains", value: "Bracelets & Chains" },
    ],
  },
  {
    value: "Bags",
    label: "Bags",
    icon: "../assets/bagsIcon.png",
    description: "",
    children: [
      { label: "Backpacks", value: "Backpacks" },
      { label: "Handbags", value: "Handbags" },
      { label: "Luggage", value: "Luggage" },
      { label: "Messenger Bags", value: "Messenger Bags" },
      { label: "Tote Bags", value: "Tote Bags" },
      { label: "Laptop Bags", value: "Laptop Bags" },
      { label: "Clutch Bags", value: "Clutch Bags" },
      { label: "Shoulder bags", value: "Shoulder bags" },
    ],
  },
  {
    value: "Tops",
    label: "Tops",
    icon: "../assets/icons/sweater.png",
    description: "",
    children: [
      { label: "Long Sleeve T-Shirts", value: "Long Sleeve T-Shirts" },
      { label: "Polos", value: "Polos" },
      { label: "Shirts (Button Ups)", value: "Shirts (Button Ups)" },
      { label: "Short Sleeve T-Shirts", value: "Short Sleeve T-Shirts" },
      { label: "Sweaters & Knitwear", value: "Sweaters & Knitwear" },
      { label: "Sweatshirts & Hoodies", value: "Sweatshirts & Hoodies" },
      { label: "Tank Tops & Sleeveless", value: "Tank Tops & Sleeveless" },
      { label: "Jerseys", value: "Jerseys" },
    ],
  },
  {
    label: "Bottoms",
    value: "Bottoms",
    icon: "../assets/icons/sweater.png",
    description: "",
    children: [
      { label: "Casual Pants", value: "Casual Pants" },
      { label: "Cropped Pants", value: "Cropped Pants" },
      { label: "Denim", value: "Denim" },
      { label: "Leggings", value: "Leggings" },
      { label: "Overalls & Jumpsuits", value: "Overalls & Jumpsuits" },
      { label: "Shorts", value: "Shorts" },
      { label: "Sweatpants & Joggers", value: "Sweatpants & Joggers" },
      { label: "Swimwear", value: "Swimwear" },
    ],
  },
  {
    label: "Footwear",
    value: "Footwear",
    icon: "../assets/icons/sneakers.png",
    description: "",
    children: [
      { label: "Boots", value: "Boots" },
      { label: "Casual Leather Shoes", value: "Casual Leather Shoes" },
      { label: "Formal Shoes", value: "Formal Shoes" },
      { label: "Hi-Top Sneakers", value: "Hi-Top Sneakers" },
      { label: "Low-Top Sneakers", value: "Low-Top Sneakers" },
      { label: "Sandals", value: "Sandals" },
      { label: "Slip Ons", value: "Slip Ons" },
    ],
  },

  {
    label: "Outerwear",
    value: "Outerwear",
    icon: "../assets/icons/sweater.png",
    description: "",
    children: [
      { label: "Bombers", value: "Bombers" },
      { label: "Cloaks & Capes", value: "Cloaks & Capes" },
      { label: "Denim Jackets", value: "Denim Jackets" },
      { label: "Heavy Coats", value: "Heavy Coats" },
      { label: "Leather Jackets", value: "Leather Jackets" },
      { label: "Light Jackets", value: "Light Jackets" },
      { label: "Parkas", value: "Parkas" },
      { label: "Raincoats", value: "Raincoats" },
      { label: "Vests", value: "Vests" },
    ],
  },
];
